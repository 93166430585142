<template>
  <b-form-input
    class="input-style text-right"
    autocomplete="off"
    placeholder="Trung"
    :size="size"
    :value="value"
    @change="onInputChange($event)"
  ></b-form-input>
</template>
<script>
import { currencyMask, unMaskMoney, convertPrice } from '@/utils/common';
export default {
  props: {
    value: {
      value: '',
      required: true,
    },
    size: {
      value: null,
      default: 'sm',
    },
  },
  data() {
    return {
      mask: currencyMask,
      bindingValue: null,
    };
  },
  created() {
    this.bindingValue = this.value;
  },
  methods: {
    onInputChange(event) {
      this.$emit('change', event);
    },
    formatter(value) {
      return convertPrice(Number(unMaskMoney(value)));
    },
  },
};
</script>
