// Japan
export const locale = {
  TRANSLATOR: {
    SELECT: 'あなたが使う言語を選んでください'
  },
  MENU: {
    NEW: '新しい',
    ACTIONS: '行動',
    CREATE_POST: '新しい投稿を作成',
    PAGES: 'Pages',
    FEATURES: '特徴',
    APPS: 'アプリ',
    DASHBOARD: 'ダッシュボード'
  },
  AUTH: {
    GENERAL: {
      OR: 'または',
      SUBMIT_BUTTON: '提出する',
      NO_ACCOUNT: 'アカウントを持っていない？',
      SIGNUP_BUTTON: 'サインアップ',
      FORGOT_BUTTON: 'パスワードをお忘れですか',
      BACK_BUTTON: 'バック',
      PRIVACY: 'プライバシー',
      LEGAL: '法的',
      CONTACT: '接触'
    },
    LOGIN: {
      TITLE: 'Create Account',
      BUTTON: 'Sign In'
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.'
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.'
    },
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: 'ユーザー名'
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid'
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields'
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: 'Customers',
      CUSTOMERS_LIST: 'Customers list',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted'
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted'
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated'
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created'
      }
    }
  }
};
