// action types
export const SET_ORDER_NOTE_INFO = 'setOrderNote';
export const PURGE_ORDER_NOTE_INFO = 'removeOrderNote';

const orderNoteBase = {
  saleNote: null,
  customerNote: null,
  warrantyNote: null
};

export default {
  state: {
    orderNote: { ...orderNoteBase }
  },
  getters: {
    /**
     * get order note
     * @param state
     * @returns {*}
     */
    getOrderNote(state) {
      return state.orderNote;
    }
  },
  actions: {
    [SET_ORDER_NOTE_INFO](state, payload) {
      state.commit(SET_ORDER_NOTE_INFO, payload);
    },
    [PURGE_ORDER_NOTE_INFO](state) {
      state.commit(PURGE_ORDER_NOTE_INFO);
    }
  },
  mutations: {
    [SET_ORDER_NOTE_INFO](state, payload) {
      state.orderNote = payload;
    },
    [PURGE_ORDER_NOTE_INFO](state) {
      state.orderNote = {...orderNoteBase};
    }
  }
};
