import {
  BASE_URL,
  GET_LIST_FAST_SYNC_LOG,
  RE_SYNC_DATA_ERROR_BY_ID,
} from './constants';

const makeUrl = (path) => `${BASE_URL}/${path}`;

export default (ApiService) => ({
  getList(queryParam = {}) {
    return ApiService.query(makeUrl(GET_LIST_FAST_SYNC_LOG), {
      params: { ...queryParam },
    });
  },
  retrySyncDataErrorById(id) {
    return ApiService.get(makeUrl(`${RE_SYNC_DATA_ERROR_BY_ID}/${id}`));
  },
});
