import * as shipmentApi from '@/core/services/api/shipment';
// action types
export const SET_SHIPMENT_ACTION = 'setShipmentAction';
export const REMOVE_SHIPMENT_ACTION = 'removeShipmentAction';
export const GET_SHIPMENT_INFO = 'getShipmentAction';
export const GET_EXISTING_SHIPMENT_ACTION = 'getExistingShipmentAction';

// mutation types
export const SET_SHIPMENT = 'setShipment';
export const REMOVE_SHIPMENT = 'removeShipment';

const shipmentBase = {
  id: null,
  entities: [],
  totalAmount: 0,
  status: 1,
  note: '',
  locationId: null,
  originAddress: '',
  longitude: null,
  latitude: null,
  senderId: null,
  senderName: '',
  senderPhone: '',
  products: [],
  destinations: [
    {
      id: null,
      address: '',
      longitude: null,
      latitude: null,
      shipperName: '',
      shipperId: null,
      receiverId: null,
      receiverName: '',
      receiverPhone: '',
      receivingMoney: 0,
      kilometer: 0,
      deleted: false,
      tripType: 1,
    },
  ],
};

export default {
  state: {
    shipment: {
      ...shipmentBase,
    },
  },
  getters: {
    getShipments(state) {
      return state.shipment;
    },
    getShipmentProducts(state) {
      return state.shipment.products || [];
    },
  },
  actions: {
    [SET_SHIPMENT_ACTION](state, payload) {
      state.commit(SET_SHIPMENT, payload);
    },
    [REMOVE_SHIPMENT_ACTION](state) {
      state.commit(REMOVE_SHIPMENT);
    },
    [GET_SHIPMENT_INFO](state, { params, success, fail } = {}) {
      shipmentApi.getShipment(
        params,
        (response) => {
          const { data } = response.data;
          state.commit(SET_SHIPMENT, data);
          success && success(data);
        },
        (response) => {
          fail && fail(response);
        },
      );
    },
    [GET_EXISTING_SHIPMENT_ACTION](state, { params, success, fail } = {}) {
      shipmentApi.getExistingShipment(
        params,
        (response) => {
          const { data } = response.data;
          state.commit(SET_SHIPMENT, data);
          success && success(data);
        },
        (response) => {
          fail && fail(response);
        },
      );
    },
  },
  mutations: {
    [SET_SHIPMENT](state, payload) {
      state.shipment = { ...payload };
    },
    [REMOVE_SHIPMENT](state) {
      state.shipment = shipmentBase;
    },
  },
};
