module.exports.ERROR_CODE = Object.freeze({
  BAD: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  ERROR_INTERNAL: 500,
  ERROR_VERSION: 900,
});

module.exports.BILL_ITEM_TYPE = Object.freeze({
  PRODUCT: 1, // sản phẩm
  PRODUCT_BONUS: 2, // sản phẩm tặng kèm
  PRODUCT_PROMTION: 3, // sản phẩm bán kèm
  RETURN_WARRANTY: 4, // sản phẩm trả bảo hành
});

module.exports.CONTACT_TYPE = Object.freeze({
  Customer: '1',
  Provider: '2',
  Installment: '3',
  Employee: '4',
  Credit: '5',
  ShiftWork: '6',
  Ecommerce: '21',
  Other: '20',
  ShipFee: '22',
});

module.exports.TRANSACTION_TYPE = Object.freeze({
  CashIn: '1', // Phiếu thu
  CashOut: '2', // Phiếu chi
  BankIn: '3', // Báo có (nộp tiền)
  BankOut: '4', // Báo nợ (Rút tiền)
  StockImport: '8', // Phiếu nhập
  GoodsIssue: '9', // Phiếu xuất hàng
  Sale: '6', // Phiếu bán hàng
  MerchandiseReturn: '5', // Phiếu bán hàng
});

module.exports.STOCK_SLIP_TYPE = Object.freeze({
  IMPORT_PROVIDER: 1, // nhập kho NCC
  EXPORT_PROVIDER: 2, // xuất kho tra hang NCC
  TRANSFER_STOCK: 3, // chuyen kho
  REQUIRE_IMPORT_STOCK: 4, // yeu cau nhap chuyen kho
  REQUIRE_EXPORT_STOCK: 5, // yeu cau xuat chuyen kho
  EXPORT_STOCK_TO_SALE: 6, // xuat kho ban le
  EXPORT_STOCK_TO_SALE_WHOLSE: 7, // xuat kho ban buon
  IMPORT_STOCK_FROM_BILL: 8, // nhap kho tu phieu tra hang
  IMPORT_STOCK_FROM_ORDER: 9, // nhap kho tu don chuyen hoan
  EXPORT_TO_SHIPPING: 10, // xuat kho giao hang
  IMPORT_FROM_TRADE_IN: 11, // nhap kho tu phiếu thu cũ
  TRANSFER_TO_NHANH: 12, // chuyen kho sang he thong cu
  REQUIRE_EXPORT_STOCK_TO_NHANH: 13, // phiếu yêu cầu xuất chuyển kho sang hệ thống cũ
  EXPORT_TRANSFER_TO_NHANH: 14, // xuat chuyen kho sang he thong cu
  IMPORT_WARRANTY: 15, // nhập kho bảo hành
  EXPORT_WARRANTY: 16, // xuất kho bảo hành
  IMPORT_HOLDING: 17, // nhập giữ hàng
  EXPORT_HOLDING: 18, // xuất trả giữ hàng
  TRANSFER_WARRANTY_STOCK: 19, // chuyển kho bảo hành
  REQUIRE_EXPORT_WARRANTY_STOCK: 20, // yeu cau xuat chuyen kho bao hanh
  IMPORT_STOCK_FROM_WARRANTY_BILL: 22, // nhap kho tu phieu bao hanh
  IMPORT_WARRANTY_FROM_BILL: 23, // nhập kho bảo hành từ hóa đơn bảo hành
  EXPORT_WARRANTY_FROM_BILL: 24, // xuất kho bảo hành từ hóa đơn bảo hành
  EXPORT_STOCK_BY_WARRANTY_BILL: 25, // xuất kho bởi trả khác mã từ hóa đơn bảo hành
  EXPORT_WARRANTY_TO_PROVIDER: 26, // Xuất kho bảo hành trả nhà cung cấp
  UPDATE_EXPORT_WARRANTY_TO_PROVIDER: 27, // cập nhật phiếu xuất kho bảo hành trả ncc
  DELETE_EXPORT_WARRANTY_TO_PROVIDER: 28, // xóa phiếu xuất kho bảo hành trả ncc
  CREATE_WARRANTY_COMPLETION_SLIP: 29, // tạo phiếu hoàn tất bảo hành
  DELETE_WARRANTY_COMPLETION_SLIP: 30, // xoá phiếu hoàn tất bảo hành
  IMPORT_STOCK_BY_WARRANTY_COMPLETION: 31, // nhập kho bởi phiếu hoàn tất bảo hành
  EXPORT_STOCK_BY_WARRANTY_COMPLETION: 32, // xuất kho bởi phiếu hoàn tất bảo hành
  EXPORT_WARRANTY_STOCK_BY_DELETE_WARRANTY_BILL: 33, // xuất kho bảo hành bởi bởi xoá hoá đơn bảo hành
  IMPORT_STOCK_BY_DELETE_WARRANTY_BILL: 34, // nhập kho bởi bởi xoá hoá đơn bảo hành
  IMPORT_STOCK_BY_DELETE_STORE_WARRANTY_BILL: 35, // nhập kho bởi bởi xoá hoá đơn bảo hành (lỗi cửa hàng)
  EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL: 36, // xuất kho bởi tạo hoá đơn bảo hành (lỗi cửa hàng)
  EXPORT_STOCK_BY_INVENTORY_CHECK: 37, // xuất kho bảo hành từ phiếu kiểm tồn
  IMPORT_STOCK_BY_INVENTORY_CHECK: 38, // nhập kho từ phiếu kiểm kê sản phẩm
  EXPORT_LENDING: 39, // phiếu cho mượn máy
  IMPORT_STOCK_LENDING: 40, // nhập kho cho mượn máy
  IMPORT_WARRANTY_FROM_PROVIDER: 41, // nhập kho bảo hành nhà cung cấp (tồn thực tế)
  EXPORT_STOCK_LOSS: 44,// Xuất kho thất thoát
  EXPORT_STOCK_CLEARANCE: 45,// Xuất kho thanh lý
  IMPORT_STOCK_LOSS: 46,// nhập kho thất thoát
});

module.exports.AMOUNT_TYPE = Object.freeze({
  MONEY: 1, // tiền mặt
  PERCENT: 2, // theo %
});

module.exports.PRODUCT_TYPE = Object.freeze({
  PRODUCT: 1, // sản phẩm
  PRODUCT_IMEI: 2, // sản phẩm IMEI
  PRODUCT_COMBO: 3, // sản phẩm combo
  PRODUCT_SERVICE: 4, // sản phẩm dịch vụ
});

module.exports.PRODUCT_STATUS = Object.freeze({
  NEW: 1, // mới
  SELLING: 2, // đang bán
  STOP_SELLING: 3, // ngừng bán
  OUT_OF_STOCK: 4, // hết hàng
});

module.exports.STOCK_SLIP_STATUS = Object.freeze({
  NEW: 1, // mới
  APPROVED: 2, // đã duyệt
  CONFIRMED: 3, // đã xác nhận
  CANCELD: 4,
});

module.exports.TYPE_ORDER = Object.freeze({
  SHOP: 1, // Mua tại quầy
  PREORDER: 2, // Đặt trước
  SHIPPING: 3, // Chuyển hàng
  EX_SHIPPING: 4, // Hãng vận chuyển
  WEBSITE: 5, // Website
});

module.exports.ITEM_TYPE = Object.freeze({
  MAIN: 1,
  GIFT: 2,
  ATTACH: 3,
});

module.exports.ORDER_STATUS = Object.freeze({
  NEW: 1, // Mới
  CONFIRMING: 2, // Đang xử lí
  CONFIRMED: 3, // Đã xác nhận
  DELIVERY: 4, // Đang giao
  PACKING: 5, // Đang đóng gói
  SUCCESS: 6, // Thành công (Tồn)
  UN_SUCCESS: 7, // Không thành công
  SYSTEM_CANCEL: 8, // Hệ thống hủy
  REFUNDED: 9, // Đã chuyển hoàn
  NO_CATCHED_PHONE: 10, // Không bắt máy
  CR_CANCEL: 11, // CR huỷ
  //TODO xoá sau khi stable
  DEPOSIT: 12, // Đã đặt cọc
  AGREE_DEPOSIT: 13, // Đồng ý cọc
  // end TODO
  PREPARED: 14, // Đã chuẩn bị (Tồn)
  TRACKING: 15, // Đang theo dõi
  KEEP_DEPOSIT: 17, // Giữ cọc IP14
});
module.exports.PRODUCT_STOCK_CHECK_STATUS = Object.freeze({
  NEW: 1, // Mới
  DONE: 2, // Hoàn Thành
  STOCK_DIFF: 3, // Phiếu lệch kho cần kế toán xử lý
  STOCK_DEBT: 4, //  truy thu lệch kho
  COMFIRM: 5, // xác nhận kiểm kê
});

module.exports.DEBT_STOCK_STATUS = Object.freeze({
  NEW: 1, // Mới
  DONE: 2, // Hoàn thành
});

module.exports.CRITERIA_FILTER_BILL = Object.freeze({
  HAVE_DISCOUNT: 1, // Có tiền chiết khấu
  NO_DISCOUNT: 2, //Không có tiền chiết khấu
  HAVE_TRANSFER: 3, // Có tiền chuyển khoản
  HAVE_CREDIT: 4, // Có thanh toán quẹt thẻ
  HAVE_INSTALLMENT: 5, // Có thanh toán trả góp
  USE_POINT: 6, // Có dùng tiền tích điểm
  HAVE_DEBT: 7, // Có công nợ
  STILL_OWE: 8, // Còn công nợ
  OUT_OF_DEBT: 9, // Hết công nợ
  HAVE_CASH: 10, // Có tiền mặt
  FROM_ORDER: 11, // Hóa đơn từ đơn hàng
  HAVE_GIFT: 12, //Có quà tặng
  HAVE_DEPOSIT: 13, // có cọc từ đơn hàng
  UNDERPAYMENT: 14, // còn thiếu tiền
  UNPAID: 15, // chưa thanh toán
  NO_CODESHIP: 16, // chưa gắn code ship
  USE_COUPON: 17,
});

module.exports.PURCHASE_ORDER_STATUS = Object.freeze({
  NEW: 1, // Mới
  PURCHASE: 2, // Ngành hàng
  ACCOUNTANT: 3, // Kế toán
  PO_HEAD_CANCEL: 4, // trưởng ngành hàng hủy phiếu
  ACCOUNTANT_CANCEL: 5, // kế toán hủy phiếu
  READY: 6, // sẵn sàng để duyệt
  CANCELED: 7, // hủy yêu cầu
  SHOP_APPROVAL: 8, // AM duyệt đơn từ cửa hàng
});

module.exports.PURCHASE_ORDER_SOURCE = Object.freeze({
  STORE: 1, // nguồn cửa hàng
  PURCHASE: 2, // nguồn ngành hàngß
});

module.exports.PURCHASE_ORDER_APPROVE_MODE = Object.freeze({
  PURCHASE: 'purchase',
  ACCOUNTANT: 'accountant',
});

module.exports.PURCHASE_ORDER_TYPE = Object.freeze({
  IMPORT: 1,
  CONSIGNMENT: 2,
});

module.exports.LOAN_APPLICATION_STATUS = Object.freeze({
  INIT: 1,
  SUBMITED: 2,
  APPROVED: 3,
  REJECTED: 4,
  DISBURSED: 5,
  OP_PROCESSING: 6,
});

module.exports.UPLOAD_ENTITY = Object.freeze({
  BILLS: 'bills',
  BILLS_INVOICE: 'bills-invoices',
  PO: 'purchase-orders',
  STOCK_SLIP: 'stock-slips',
  TRANSACTIONS: 'transactions',
  ORDERS: 'orders',
});

module.exports.CUSTOMER_TYPE = Object.freeze({
  RETAIL_CUSTOMER: 1, // khách lẻ
  WHOLESALE_CUSTOMER: 2, // khách buôn
  AGENCY: 3, // đại lý
  VIP: 4, // vip
  KOL: 5, // kol
});

module.exports.PRODUCT_SEARCH_TYPE = Object.freeze({
  PRODUCT_NAME: 1, // tìm theo tên, mã, mã vạch
  IMEI_CODE: 2, // tìm theo mã IMEI
  FLEXIBLE_COMBO: 3,
});

module.exports.FILE_UPLOAD_STATUS = Object.freeze({
  NEW: 1, // Mới
  EXIST: 2, // Tồn tại
});

module.exports.FILE_UPLOAD_MODE = Object.freeze({
  INITIAL: 0, // Mới
  SAVING: 1, // Tồn tại
  SUCCESS: 2, // Tồn tại
  FAILED: 3, // Tồn tại
});

module.exports.JOB_TITLE = Object.freeze({
  GD: 1, // Giám đốc
  RSM: 2, // Quản lý vùng
  ASM: 3, // Quản lý khu vực
  QL: 4, // Quản lý cửa hàng
  TL: 5, // Trợ lý cửa hàng
  KTBH: 6, // Kế toán bán hàng
  TVBH: 7, // Tư vấn bán hàng
  KTPM: 8, // Hỗ trợ kỹ thuật
  TDKH: 9, // Tiếp đón khách hàng
  KTPC: 10, // Kỹ thuật phần cứng
  GDNHPK: 11, // Giám đốc ngành hàng phụ kiện
  NVNHPK: 12, // NV phụ trách ngành hàng PK
  ADMIN_NHPK: 13, // Admin ngành hàng phụ kiện
  CDPK: 14, // nhân viên chuyên doanh phụ kiện
  QL_CSKH: 15, // quản lý CR
  CSKH: 16, // nhân viên CR
  CTV_CSKH: 17, // cộng tác viên CR
  ADMIN_GDNHPK: 18, // Admin giám đốc ngành hàng phụ kiện
  KTNB: 19, // Kế toán nội bộ
  QL_DP: 20, // Trưởng bộ phận điều phối
  MKT: 21, // Nhân viên marketing
  QL_MKT: 22, // Trưởng marketing
  DP: 23, // Điều phối
  QL_KTNB: 24, // Trưởng kế toán nội bộ
  NV_QA: 25, // Nhân viên kiểm định chất lượng (Kiểm soát/QA)
  GDNH: 35, // Giám đốc ngành hàng
  HV_QL: 40, // Học việc quản lý
  HV_TL: 41, // Học việc trợ lý
  ADMIN_MKT: 57, // Admin marketing
  TRADE_MKT: 94, // Trade marketing
  TRADE_MKT_MANAGER: 106, // Trade marketing manager
  CR_LEADER_LQD: 88, // Team leader CR LQĐ
  CR_LEADER_TQK: 89, // Team leader CR TQK
});

module.exports.JOB_TITLE_ORDER = Object.freeze({
  CR: [this.JOB_TITLE.QL_CSKH, this.JOB_TITLE.CSKH, this.JOB_TITLE.CTV_CSKH],
  SHOP: [
    this.JOB_TITLE.ASM,
    this.JOB_TITLE.QL,
    this.JOB_TITLE.TL,
    this.JOB_TITLE.KTBH,
    this.JOB_TITLE.TVBH,
    this.JOB_TITLE.KTPM,
    this.JOB_TITLE.TDKH,
    this.JOB_TITLE.KTPC,
  ],
  DP: [this.JOB_TITLE.DP, this.JOB_TITLE.QL_DP],
});

module.exports.PARTNER_PAYMENT = Object.freeze({
  SUCCESS: 1, // Thành công
  FAILED: 2, // Thất bại
  AGREE_DEPOSIT: 3, // Đồng ý cọc
  DEPOSIT: 4, // Đã đặt cọc
});

module.exports.HISTORY_TYPE = Object.freeze({
  ORDER: 1, // đơn hàng
  BILL: 2, // bán hàng
  PRODUCT: 3, // sản phẩm
  PRODUCT_IMEI: 4, // sản phẩm IMEI
  PRE_ORDER: 5, // đơn đặt trước
  STOCK_SLIP: 6, // phiếu kho
});

module.exports.HISTORY_ACTION_TYPE = Object.freeze({
  UPDATE: 1, // cập nhật
  DELETE: 2, // xóa
  CREATE: 3, // tạo mới
});

module.exports.MODAL_TYPE_UPSERT_ORDER = Object.freeze({
  GIFT: 'gift',
  ATTACH: 'attach',
  PRODUCT_IMEI: 'note',
  CHANGE_GIFT: 'change-gift',
  CHANGE_MAIN: 'change-main',
});

module.exports.PAYMENT_TYPE = Object.freeze({
  CASH: 1,
  TRANSFER: 2,
  CREDIT: 3,
  INSTALLMENT: 4,
  CREDIT_FEE: 5,
  REFUND: 6,
  INSTALLMENT_FEE: 7,
});

module.exports.BILL_TYPE = Object.freeze({
  RETAIL_INVOICE: 1, // hóa đơn bán lẻ
  WHOLESALE_INVOICE: 2, // hóa đơn bán buôn
  REFUND_INVOICE: 3, // trả hàng
  SHIPPING: 4, // hóa đơn giao hàng
  IMPORT_STOCK_FROM_TRANSFER: 5, // enum này dùng cho phiếu nhập kho từ phiếu chuyển kho
  WARRANTY: 6, // hóa đơn bảo hành
  CONVERT: 7, // hóa đơn chuyển đổi
});
module.exports.WARRANTY_BILL_STATUS = Object.freeze({
  NEW: 1, // mới
  PROCESSING: 2, // đang xử lý
  DONE: 3, // đã trả bảo hành
  TRANSFER_STOCK: 4, // đang chuyển kho BH
  //One Touch Point
  SENT_REVENUE: 10, // Đã chuyển doanh thu
  VERIFY_REVENUE: 11, // Đã xác nhận doanh thu
});
module.exports.WARRANTY_REASON_STATUS = Object.freeze({
  ACTIVE: 0, // hoạt động
  INACTIVE: 1, // không hoạt động
});
module.exports.WARRANTY_REASON_GROUP_STATUS = Object.freeze({
  ACTIVE: 0, // hoạt động
  INACTIVE: 1, // không hoạt động
});
module.exports.PO_RELATION_TYPE = Object.freeze({
  INDEPENDENT: 1,
  PARENT: 2,
  CHILD: 3,
});
module.exports.BILL_VAT_TYPE = Object.freeze({
  PERSONAL: 1,
  COMPANY: 2,
});

module.exports.VAT_INVOICE_STATUS = Object.freeze({
  NEW: 1,
  PROCESSING: 2,
  PUBLIC: 3,
  CUSTOMER_SENT: 4,
  REFUND: 5,
});


module.exports.OPERATOR_TYPE = Object.freeze({
  NONE: 1, // không xác định (--)
  LT: 2, // nhỏ hơn (<)
  GT: 3, // lớn hơn (>)
  LTE: 4, // nhỏ hơn hoặc bằng (<=)
  GTE: 5, // lớn hơn hoặc bằng (>=)
});

module.exports.APPROVAL_STEP_STATUS = Object.freeze({
  NEW: 1,
  APPROVED: 2,
  CANCELED: 3,
  PAID: 4,
  PAY_FAILED: 5,
  COMPLETED: 6,
  ON_APPROVE: 7,
  CHECKED: 8,
});

module.exports.APPROVAL_TYPE_ENUM = Object.freeze({
  OVER_PAYMENT: 1, // thanh toán dư
  BUY_OLD: 2, // thu cũ
  REFUND: 3, // trả hàng
  TRADE_IN: 4, // thu cũ đổi mới
  VAT: 5, // xuất hóa đơn VAT
  DEPOSIT: 6, // hoàn cọc
});

module.exports.APPROVAL_PAYMENT_TYPE = Object.freeze({
  CASH: 1,
  TRANSFER: 2,
});

module.exports.VAT_INVOICE_PUSH = Object.freeze({
  UN_APPROVED: 1,
  APPROVED: 2,
  SUBMITED: 3,
});

module.exports.VAT_AMOUNT = Object.freeze({
  PERCENT_0: 0,
  PERCENT_8: 8,
  PERCENT_10: 10,
  PERCENT_KCT: -1,
});

module.exports.TRANSFER_STORE_FORM_STATUS = Object.freeze({
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  APROVED_TRANSFER: 4,
});

module.exports.JOB_TITLE_EMLOYEE_STORE = Object.freeze([
  this.JOB_TITLE.KTBH,
  this.JOB_TITLE.TVBH,
  this.JOB_TITLE.KTPM,
  this.JOB_TITLE.TDKH,
  this.JOB_TITLE.KTPC,
]);

module.exports.JOB_TITLE_SHOW_BY_OWNER = Object.freeze([
  this.JOB_TITLE.QL,
  this.JOB_TITLE.TL,
]);

module.exports.JOB_TITLE_APPROVE = Object.freeze([
  this.JOB_TITLE.ASM,
  this.JOB_TITLE.RSM,
  this.JOB_TITLE.GD,
]);

module.exports.ENVIRONMENT = Object.freeze({
  BETA_FEATURE_STORE_ID: 88036,
});

module.exports.STORE_TYPE = Object.freeze({
  NEG: 1,
});

module.exports.VAT_INVOICE_PUSH = Object.freeze({
  UN_APPROVED: 1,
  APPROVED: 2,
  SUBMITED: 3,
});

module.exports.VAT_AMOUNT = Object.freeze({
  PERCENT_0: 0,
  PERCENT_8: 8,
  PERCENT_10: 10,
  PERCENT_KCT: -1,
});

module.exports.DELETE_TYPE = Object.freeze({
  AVAILABLE: 0,
  DELETED: 1,
});

module.exports.VAT_SYNC_STATUS = Object.freeze({
  NEW: 1,
  SENT_FAIL: 2,
  SENT_SUCCESS: 3,
  PUBLICH: 4,
  CANCELED: 5,
});

module.exports.PARTNER_API_STATUS = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
});

module.exports.PARTNER_API_DETAIL_TYPE = Object.freeze({
  PRODUCT: 1,
  STORE: 2,
  CATEGORY: 3,
});

module.exports.ACTION_TYPE = Object.freeze({
  CREATE: 1,
  UPDATE: 2,
});

module.exports.PAYMENT_STATUS = Object.freeze({
  SUCCESS: 1,
  FAILED: 2,
});

module.exports.ACCOUNTANT_STATUS = Object.freeze({
  ACTIVE: 1,
  INACTIVATE: 2,
});

module.exports.REFERENCE_TYPE = Object.freeze({
  BILL: 1,
  ORDER: 2,
});

module.exports.DELETE_TYPE = Object.freeze({
  AVAILABLE: 0,
  DELETED: 1,
});

module.exports.COUPON_STATUS = Object.freeze({
  ACTIVATE: 1,
  INACTIVATE: 0,
});

module.exports.PAYROLL_TYPE = Object.freeze({
  STANDARD_WORKING_HOUR: 1, // Theo giờ công chuẩn
  STANDARD_NIGHT_WORKING_DAY: 2, // Theo ngày công chuẩn làm đêm
  STANDARD_NIGHT_WORKING_HOUR: 3, // Theo giờ công chuẩn làm ban đêm
  STANDARD_WORKING_HOUR_IMPORTED: 4, // Theo ngày công chuẩn import
  STANDARD_WORKING_DAY: 5, // Theo ngày công chuẩn
  EXEMPT_FROM_TIMEKEEPING: 6, // Theo miễn chấm công
  TARGET_REPORT: 7, // Theo báo cáo mục tiêu
});

module.exports.TRADE_IN_TYPE = Object.freeze({
  TRADE_IN: 1, // Khách thu cũ đổi mới
  BUYING_OLD_ITEMS: 2, // Khách thu cũ không đổi mới
  EXCHANGE_WARRANTY: 3, // Khách đổi bảo hành
});

module.exports.BILL_STATE = Object.freeze({
  DELETED: 'DELETED',
  NO_AMOUNT: 'NOAMOUNT',
});

module.exports.IMEI_STATUS = Object.freeze({
  NEW: 1, // Mới
  SALED: 2, // Đã bán
  TRANSFERING: 3, // Đang vận chuyển
  ERROR: 4, // Lỗi
  RETURN_TO_SUPPLIER: 5, // Đã trả NCC
  STOCK_TRANSFER: 6, // Đang chuyển kho
  EXPORT_WARRANTY: 7, // đã trả bảo hành
  ON_WARRANTY: 8, // đang bảo hành
  ON_HOLDING: 9, // đang giữ hàng
  ON_LENDING: 10, // đang cho mượn
  LOSS: 11 // thất thoát
});

module.exports.POSM_INPUT_TYPE = Object.freeze({
  TEXT: 1,
  IMAGE: 2,
  FILE: 3,
  DROPDOWN: 4,
});
module.exports.POSM_TYPE = Object.freeze({
  EXTERIOR: 1,
  INTERIOR: 0,
});

module.exports.POSM_GENERAL_LOCATIONS = Object.freeze({
  EXTERIOR_CATEGORIES: 'exteriorCategories',
  INTERIOR_CATEGORIES: 'interiorCategories',
});

module.exports.STOCK_HISTORY = Object.freeze({
  IMPORT_STOCK: 1, // nhap kho tu nha cung cap
  EXPORT_STOCK: 2, // xuat kho tra nha cung cap
  TRANSFER_STOCK: 3, // chuyen kho
  UPDATE_IMPORT_STOCK: 4, // cap nhat phieu nhap kho
  UPDATE_EXPORT_STOCK: 5, // cap nhat phieu xuat kho
  EXPORT_TO_TRANSFER: 6, // xuat chuyen kho
  IMPORT_FROM_TRANSFER: 7, // nhap chuyen kho
  EXPORT_TO_SALE_RETAIL: 8, // xuat kho ban le
  EXPORT_TO_SALE_WHOLE_SASE: 9, // xuat kho bán buon
  IMPORT_FROM_RETURN_BILL: 10, // nhap kho tu phieu tra hang
  IMPORT_FROM_REFUND_ORDER: 11, // nhập kho từ đơn chuyển hoàn
  EXPORT_TO_SHIPPING: 12, // xuất kho giao hàng
  INVENTORY_BALANCE: 13, // cân kho
  IMPORT_WARRANTY: 14, // nhập kho bảo hành
  UPDATE_IMPORT_WARRANTY: 15, // cập nhật nhập kho bảo hành
  EXPORT_WARRANTY: 16, // xuất kho bảo hành
  UPDATE_EXPORT_WARRANTY: 17, // cập nhật xuất kho bảo hành
  CHANGE_PRODUCT_IMEI: 18, // đổi sản phẩm IMEI
  REMOVE_FROM_BILL: 19, // xóa SP trong hóa đơn
  REMOVE_BILL: 20, // xóa hóa đơn
  REMOVE_RETURN_BILL: 21, // xóa hóa đơn trả hàng
  UPDATE_EXPORT_TO_SALE: 22, // cập nhật hóa đơn
  DELETE_TRANSFER_STOCK: 23, // xóa phiếu chuyển kho
  DELETE_IMPORT_STOCK: 24, // xoa phieu nhap kho tu nha cung cap
  DELETE_EXPORT_STOCK: 25, // xoa phieu xuat kho tra nha cung cap
  IMPORT_FROM_TRADE_IN: 26, // nhập kho thu cũ
  CANCEL_TRANSFER: 27, // hủy phiếu chuyển
  IMPORT_HOLDING: 28, // nhập kho giữ hàng
  UPDATE_IMPORT_HOLDING: 29, // cập nhật nhập kho giữ hàng
  DELETE_IMPORT_HOLDING: 30, // xóa phiếu nhập kho giữ hàng
  EXPORT_HOLDING: 31, // xuất trả giữ hàng
  DELETE_EXPORT_HOLDING: 32, // xóa phiếu xuất trả giữ hàng
  CANCEL_HOLDING: 33, // hủy phiếu giữ hàng
  DELETE_IMPORT_WARRANTY: 34, // xoa phieu nhap kho tu bao hanh
  DELETE_EXPORT_WARRANTY: 35, // xoa phieu xuat kho tra bao hanh
  DELETE_IMPORT_TRADE_IN: 36, // xoa phieu nhap kho tu bao hanh
  IMPORT_WARRANTY_FROM_BILL: 37, // nhập kho bảo hành từ hóa đơn
  EXPORT_WARRANTY_FROM_BILL: 38, // xuất kho bảo hành từ hóa đơn
  UPDATE_IMPORT_WARRANTY_FROM_BILL: 39, // cập nhật nhập kho bảo hành từ hóa đơn
  UPDATE_EXPORT_WARRANTY_FROM_BILL: 40, // cập nhật xuất kho bảo hành từ hóa đơn
  DELETE_IMPORT_WARRANTY_FROM_BILL: 41, // xóa phiếu nhập kho bảo hành từ hóa đơn
  DELETE_EXPORT_WARRANTY_FROM_BILL: 42, // xóa phiếu xuất kho bảo hành từ hóa đơn
  EXPORT_STOCK_BY_WARRANTY_BILL: 43, // xuất kho bởi đổi sp từ hóa đơn bảo hành
  DELETE_REQUIRE_EXPORT_WARRANTY_STOCK: 44, // xóa phiếu yêu cầu xuất nhập kho bảo hành
  EXPORT_WARRANTY_TO_PROVIDER: 45, // xuất kho trả nhà cung cấp
  UPDATE_EXPORT_WARRANTY_TO_PROVIDER: 46, // cập nhật xuất kho trả nhà cung cấp
  DELETE_EXPORT_WARRANTY_TO_PROVIDER: 47, // xóa xuất kho trả nhà cung cấp
  IMPORT_STOCK_BY_WARRANTY_COMPLETION: 48, // nhập kho bởi phiếu hoàn tất bảo hành
  EXPORT_STOCK_BY_WARRANTY_COMPLETION: 49, // xuất kho bởi phiếu hoàn tất bảo hành
  CREATE_WARRANTY_COMPLETION_SLIP: 50, // xuất kho bởi phiếu hoàn tất bảo hành
  DELETE_WARRANTY_COMPLETION_SLIP: 51, // xuất kho bởi phiếu hoàn tất bảo hành
  DELETE_IMPORT_STOCK_BY_WARRANTY_COMPLETION: 52, // xoá phiếu nhập kho bởi phiếu hoàn tất bảo hành
  DELETE_EXPORT_STOCK_BY_WARRANTY_COMPLETION: 53, // xoá phiếu xuất kho bởi phiếu hoàn tất bảo hành
  EXPORT_WARRANTY_STOCK_BY_DELETE_WARRANTY_BILL: 54, // xuất kho bảo hành bởi xoá hoá đơn bảo hành
  IMPORT_STOCK_BY_DELETE_WARRANTY_BILL: 55, // nhập kho bởi xoá hoá đơn bảo hành
  IMPORT_STOCK_BY_DELETE_STORE_WARRANTY_BILL: 56, // nhập kho bởi xoá hoá đơn bảo hành (lỗi do cửa hàng)
  EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL: 57, // xuất kho bởi tạo hoá đơn bảo hành (lỗi do cửa hàng)
  DELETE_EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL: 58, // nhập kho bởi xoá đơn bảo hành(do lỗi phát sinh cửa hàng)
  EXPORT_STOCK_BY_INVENTORY_CHECK: 59, // xuất kho từ phiếu kiểm kê sản phẩm
  IMPORT_STOCK_BY_INVENTORY_CHECK: 60, // nhập kho từ phiếu kiểm kê sản phẩm
  EXPORT_LENDING: 61, // xuất kho cho mượn máy
  UPDATE_EXPORT_LENDING: 62, // cập nhật phiếu xuất kho cho mượn máy
  DELETE_EXPORT_LENDING: 63, // xoá phiếu xuất kho cho mượn máy
  IMPORT_STOCK_LENDING: 64, // nhập kho nhận máy cho mượn
  IMPORT_WARRANTY_FROM_PROVIDER: 65, // nhập kho sản phẩm bảo hành từ nhà cung cấp
});

module.exports.WARRANTY_ERROR_BY = Object.freeze({
  BILL: 1, // lỗi do hoá đơn
  STORE: 2, // lỗi do cửa hàng
});

module.exports.LENDING_SLIP_STATUS = Object.freeze({
  BORROWING: 1, // Đang cho mượn
  RETURNED: 2, // Đã trả máy
});

module.exports.CUSTOMER_APPELLATION = Object.freeze({
  MR: 1, // Anh/Ông
  MS: 2, // Chị/Bà
});

module.exports.PO_SENT_STATUS = Object.freeze({
  NEW: 0,
  SENT_FAIL: 1,
  SENT_SUCCESS: 2,
});

module.exports.COUPON_SCOPE_CHANNEL = {
  BOTH: 1,
  OFFLINE: 2,
  ONLINE: 3,
};

module.exports.DISCOUNT_AMOUNT_TYPE = Object.freeze({
  MONEY: 1,
  PERCENT: 2,
});

module.exports.APPROVAL_ENTITY_TYPE = Object.freeze({
  BILL: 1, // hóa đơn bán hàng
  ORDER: 2, // đơn hàng
  STOCK_SLIP: 3, // phiếu thu cũ
});

module.exports.DEALSHEET_STATUS = Object.freeze({
  ACTIVE: 1, //hoạt động
  INACTIVE: 0, //ngưng hoạt động
});
module.exports.DEALSHEET_TYPE = Object.freeze({
  HOT_SALE: 1, //hoạt động
  JBP: 2, //ngưng hoạt động
});

module.exports.TRADE_IN_STATUS = Object.freeze({
  QUOTE: 1, // đã báo giá
  COLLECTED: 2, // đã thu cũ
});

module.exports.SAMSUNG_ACTIVE_STATUS = {
  CHK_FMT_I2: 2, // ngay kich hoat khong hop le
  IMEI_ACTV: 4, // Kích hoạt thành công
  IMEI_DUPL: 5, // Máy bán ra sau khi kích hoạt trước
  IMEI_NO: 6, // IMEI chưa được kích hoạt thành công
  IMEI_NOMOD: 8, // IMEI nhập ngoài
  IMEI_OLDMD: 15, // IMEI quá hạn bảo hành
  IMEI_VALID: 10, // Mã IMEI hợp lệ
  IMEI_WRGFM: 11, // IMEI sai
  NOAUTH2: 13, // Sitecode sai
};

module.exports.SALARY_REPORT_STATUS = Object.freeze({
  NONE: 0,
  NEW: 1,
  PROCESSING: 2,
  DONE: 3,
});

module.exports.BUSINESS_STATUS = Object.freeze({
  ACTIVE: 1,
  INACTIVATE: 2,
});

module.exports.SHIPMENT_ENTITY_TYPE = Object.freeze({
  BILL: 1,
  ORDER: 2,
  STOCK_SLIP: 3,
});

module.exports.SHIPMENT_TRIP_TYPE = Object.freeze({
  FULL_ROAD: 1,
  HAFT_ROAD: 2,
  FREE_ROAD: 3,
});

module.exports.USER_ACCESS_STATUS = Object.freeze({
  ACTIVE: 1,
  DEACTIVE: 2,
});

module.exports.STOCK_SLIP_RELATION_TYPE = Object.freeze({
  INDEPENDENT: 1, // Phiếu độc lập
  PARENT: 2, // Phiếu tổng
  CHILD: 3, // Phiếu con
});

module.exports.TICKET_STATUS = Object.freeze({
  NEW: 0,
  RECORDED_INFOMATION: 1,
  PROCESSING: 2,
  COMPLETED: 3,
  REJECT: 4,
});

module.exports.TICKET_TYPE_STATUS = Object.freeze({
  OFF: 0,
  ON: 1,
});

module.exports.FUNCTION_ACTION_TICKET = Object.freeze({
  POINT_TRANSFER: 'POINT_TRANSFER',
});

module.exports.POINT_TRANSFER_STATUS = Object.freeze({
  NEW: 0,
  COMPLETED: 1,
  REJECT: 2,
});

module.exports.STATUS_CODE = Object.freeze({
  SUCCESS: 1, // thành công
  ERROR: 0, // thất bại
});

module.exports.MODULE_TYPE = Object.freeze({
  CUSTOMER: 1,
  ORDER: 2,
  BILL: 3,
  PRODUCT: 4,
  PRODUCT_IMEI: 5,
  TRADE_IN: 7,
  STOCK: 8,
  PRE_ORDER: 9,
});

module.exports.STOCK_SEARCH_TYPE = Object.freeze({
  REQUIRE_EXPORT_STOCK: 12,
});

module.exports.FUNCTION_ACTION_TICKET = Object.freeze({
  POINT_TRANSFER: 'POINT_TRANSFER',
  ADD_ORDER_FOR_BILL: 'ADD_ORDER_FOR_BILL',
  PHONE_NUMBER_CHANGE: 'PHONE_NUMBER_CHANGE',
});

module.exports.POINT_TRANSFER_STATUS = Object.freeze({
  NEW: 0,
  COMPLETED: 1,
  REJECT: 2,
});

module.exports.STATUS_CODE = Object.freeze({
  SUCCESS: 1, // thành công
  ERROR: 0, // thất bại
});

module.exports.STOCK_SLIP_RELATION_TYPE = Object.freeze({
  INDEPENDENT: 1, // Phiếu độc lập
  PARENT: 2, // Phiếu tổng
  CHILD: 3, // Phiếu con
});

module.exports.STOCK_SEARCH_TYPE = Object.freeze({
  REQUIRE_EXPORT_STOCK: 12,
  EXPORT_STOCK_LOSS: 13, //xuất kho thất thoát
  EXPORT_STOCK_CLEARANCE: 13, // xuất kho thanh lý
  IMPORT_STOCK_LOSS: 14, // nhập kho thất thoát
  IMPORT_ADJUST_STOCK_SLIP: 15, // nhập cân kho
  EXPORT_ADJUST_STOCK_SLIP: 16 // xuất cân kho
});

module.exports.STATUS_CODE = Object.freeze({
  SUCCESS: 1, // thành công
  ERROR: 0, // thất bại
});


module.exports.STORE_PO_STATUS = Object.freeze({
  NEW: 1, // mới
  APPROVED: 2, // đã duyệt
  CONFIRMED: 3, // đã xác nhận
  CANCELD: 4, // đã hủy
  REQUEST_APPROVAL: 5, // yêu cầu duyệt
  COMPLETED: 6, // Đã  hoàn thành
});
