import Vue from 'vue';
import DatePicker from 'vue-bootstrap-datetimepicker';
import jQuery from 'jquery';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});
Vue.use(DatePicker);
