// action types
export const SET_ORDER_STATUS_SELECTED = 'setOrderStatus';
export const PURGE_ORDER_STATUS_SELECTED = 'removeOrderStatus';

const orderStatusBase = [];

export default {
  state: {
    orderSeleted: [...orderStatusBase]
  },
  getters: {
    /**
     * get order status
     * @param state
     * @returns {*}
     */
    getOrderStatus(state) {
      return state.orderSeleted;
    }
  },
  actions: {
    async [SET_ORDER_STATUS_SELECTED](state, payload) {
      state.commit(SET_ORDER_STATUS_SELECTED, payload);
    },
    [PURGE_ORDER_STATUS_SELECTED](state) {
      state.commit(PURGE_ORDER_STATUS_SELECTED);
    }
  },
  mutations: {
    [SET_ORDER_STATUS_SELECTED](state, payload) {
      state.orderSeleted = payload;
    },
    [PURGE_ORDER_STATUS_SELECTED](state) {
      state.orderSeleted = [...orderStatusBase];
    }
  }
};
