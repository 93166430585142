import ApiService from '@/core/services/api.service';

const getShipment = (params, success, fail) => {
  ApiService.post('v2/shipments/init-v1', params)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

const getExistingShipment = (params, success, fail) => {
  if (!params.shipmentId) return;
  ApiService.get(`v2/shipments/${params.shipmentId}`)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

export { getShipment, getExistingShipment };
