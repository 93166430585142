import * as storeApi from '@/core/services/api/store';
// action types
export const ACTION_GET_STORES = 'getAllStoreAction';

// mutations
export const SET_STORE = 'setStore';

const storeBase = {
  stores: [],
};

export default {
  state: {
    stores: {
      ...storeBase,
    },
  },
  getters: {
    getStores(state) {
      return state.stores;
    },
  },
  actions: {
    [ACTION_GET_STORES](state, { success, fail } = {}) {
      storeApi.getAllStore(
        (response) => {
          const { data } = response.data;
          state.commit(SET_STORE, data);
          success && success(data);
        },
        (response) => {
          fail && fail(response);
        },
      );
    },
  },
  mutations: {
    [SET_STORE](state, payload) {
      state.stores = [...payload];
    },
  },
};
