import config from './config.json';

const requiredField = setting => {
  const apiBaseUrl = config[setting] || null;

  if (!apiBaseUrl) {
    throw new Error('Cấu hình server không hợp lệ');
  }

  if (!config.version) {
    throw new Error('Yêu cầu phiên bản version');
  }

  if (!apiBaseUrl.read) {
    throw new Error('Yêu cầu url read');
  }

  if (!apiBaseUrl.write) {
    throw new Error('Yêu cầu url write');
  }
};

export function getServer(setting) {
  const apiBaseUrl = config[setting] || null;

  requiredField(setting);

  return {
    ...apiBaseUrl,
    version: config.version
  };
}

export function getSetting() {
  return {
    ...config
  };
}
