// actions types
export const HANDLE_SET_SELECTED_REQUIRED_STOCK =
  'handleSetSelectedRequiredStock';
export const HANDLE_GET_SELECTED_REQUIRED_STOCK =
  'handleGetSelectedRequiredStock';

// mutation types
export const SET_SELECTED_REQUIRED_STOCK = 'setSelectedRequiredStock';
export const PURGE_SELECTED_REQUIRED_STOCK = 'removeSelectedRequiredStock';

const initialState = {
  selectedRequiredStocks: [],
};

export default {
  state: { ...initialState },
  getters: {
    getListValidForIssueTransferPortalFast(state) {
      const clonedState = { ...state };
      const listValid = clonedState.selectedRequiredStocks.filter(
        (item) => item.issuePortalFastStatus.isAvailableForIssue,
      );
      return listValid;
    },
  },
  actions: {
    [HANDLE_SET_SELECTED_REQUIRED_STOCK](state, payload) {
      state.commit(SET_SELECTED_REQUIRED_STOCK, payload);
    },
    [HANDLE_GET_SELECTED_REQUIRED_STOCK](state) {
      return { ...state.state.selectedRequiredStocks };
    },
  },
  mutations: {
    [SET_SELECTED_REQUIRED_STOCK](state, payload) {
      state.selectedRequiredStocks = payload;
    },
  },
};
